<template>
  <fragment>
    <div class="page-header">
      <div class="header-contents">
        <!-- <h1>{{ $apollo.loading ? "Loading" : client.name }}</h1> -->
        <bread-crumbs :breadcrumbs="breadcrumbs" />
      </div>
      <div class="header-aside">
        <search />
      </div>
    </div>
    <tabs :tabs="tabs">
      <template #locations="{ tab }"><client-locations @onDataLoaded="updateTab(tab, $event)" /></template>
      <!-- <template #partnerRequests="{ tab }"><client-partner-requests @onDataLoaded="updateTab(tab, $event)" /></template> -->
      <!-- <template #documents="{ tab }"><client-documents @onDataLoaded="updateTab(tab, $event)" /></template> -->
      <!-- <template #finances="{ tab }"><client-finances @onDataLoaded="updateTab(tab, $event)" /></template> -->
      <!-- <template #contacts="{ tab }"><client-contacts @onDataLoaded="updateTab(tab, $event)" /></template>
      <template #clientSetup="{ tab }"><client-setup @onDataLoaded="updateTab(tab, $event)" /></template>
      <template #branding="{ tab }"><client-branding @onDataLoaded="updateTab(tab, $event)" /></template> -->
    </tabs>
  </fragment>
</template>

<script>
import ClientLocations from "@/modules/admin/clients/tabs/ClientPartnerRequests";
// import ClientDocuments from "@/modules/admin/clients/tabs/ClientDocuments";
// import ClientFinances from "@/modules/admin/clients/tabs/ClientFinances";
// import { GET_CLIENT_DETAILS } from "@/modules/admin/clients/graph/queries";
// import ClientContacts from "@/modules/admin/clients/tabs/ClientContacts";
// import ClientSetup from "@/modules/admin/clients/setup/ClientSetup";
// import ClientBranding from "@/modules/admin/clients/tabs/ClientBranding";
import { mapGetters } from "vuex";
import Search from "@/components/search/Search";
import { Fragment } from "vue-fragment";
import { Tabs, BreadCrumbs } from "@/components";
// import ClientPartnerRequests from "@/modules/admin/clients/tabs/ClientPartnerRequests";

export default {
  name: "Client",
  components: {
    ClientLocations,
    //ClientDocuments,
    // ClientFinances,
    // ClientPartnerRequests,
    Search,
    // ClientBranding,
    // ClientSetup,
    // ClientContacts,
    Tabs,
    BreadCrumbs,
    Fragment,
  },
  data() {
    return {
      client: {},
      tabs: [
        { key: "locations", label: "Zones" },
        // { key: "partnerRequests", label: "Locations" },
        //{ key: "documents", label: "Documents" },
        // { key: "finances", label: "Finances" },
        // { key: "contacts", label: "Contacts" },
        // { key: "clientSetup", label: "Client Setup" },
        // { key: "branding", label: "Branding" },
      ],
    };
  },
  computed: {
    ...mapGetters(["isFlyoutShowing"]),
    showUI() {
      return !this.$route.meta.hideUI;
    },
    // clientId() {
    //   return this.$route.params.id;
    // },
    // clientName() {
    //   return this.client.name;
    // },
    breadcrumbs() {
      return [{ name: "Zones", path: "locations" }];
    },
  },
  // watch: {
  //   client(newValue) {
  //     this.$store.commit("updateClientName", newValue["name"]);
  //   },
  // },
  methods: {
    updateTab(selectedTab, error) {
      this.tabs.filter((tab) => tab.key === selectedTab.key).forEach((tab) => (tab.error = error));
      this.tabs = [...this.tabs];
    },
  },
  // apollo: {
  //   client: {
  //     query: GET_CLIENT_DETAILS,
  //     variables() {
  //       return {
  //         id: this.$route.params.id,
  //       };
  //     },
  //     update: (data) => data.clients[0],
  //     result({ data }) {
  //       this.client = data.clients[0];
  //     },
  //     skip() {
  //       return !this.$route.params.id;
  //     },
  //   },
  // },
};
</script>
